exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-doc-mobile-js": () => import("./../../../src/pages/doc/mobile.js" /* webpackChunkName: "component---src-pages-doc-mobile-js" */),
  "component---src-pages-doc-profile-js": () => import("./../../../src/pages/doc/profile.js" /* webpackChunkName: "component---src-pages-doc-profile-js" */),
  "component---src-pages-doc-web-js": () => import("./../../../src/pages/doc/web.js" /* webpackChunkName: "component---src-pages-doc-web-js" */),
  "component---src-pages-estimate-projects-js": () => import("./../../../src/pages/estimate-projects.js" /* webpackChunkName: "component---src-pages-estimate-projects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-job-list-js": () => import("./../../../src/templates/job-list.js" /* webpackChunkName: "component---src-templates-job-list-js" */),
  "component---src-templates-job-post-js": () => import("./../../../src/templates/job-post.js" /* webpackChunkName: "component---src-templates-job-post-js" */)
}

